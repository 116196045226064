const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel', 'Drivetrain'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const landingPage = globalThis.Convermax?.fitmentSearch?.vehicleStore;

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/brands/';
const manufacturersPageUrl = `/manufacturers/`;

globalThis.Convermax.removeSpecialCharacters = (value) =>
  value.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ');

function getFitmentSearchTitle() {
  const { document } = window;

  const categoryTitle = document.querySelector('.page.category h1.page-heading');
  if (categoryTitle) {
    return categoryTitle.textContent;
  }

  const brandTitle = document.querySelector('h1.brand-page-heading');
  if (brandTitle) {
    return `${brandTitle.textContent} Parts`;
  }

  return 'Parts';
}

function getActiveCurrency() {
  return document.querySelector(`[data-dropdown=currencySelection]`)?.textContent.trim().toLowerCase();
}

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const activeCurrency = getActiveCurrency();
  // use en-CA for USD and en-US for CAD to show both US$/CA$ symbols
  const invertedLocale = activeCurrency === 'usd' ? 'en-CA' : 'en-US';
  return new Intl.NumberFormat(invertedLocale, {
    style: 'currency',
    currency: activeCurrency,
  }).format(price);
}

const SearchResultCallback = () => {
  const tooltipButtons = [
    '.tooltip_free-shipping',
    '.tooltip_availability-stock',
    '.tooltip_availability-out-of-stock',
    '.tooltip_availability-backorder',
  ];

  tooltipButtons.forEach((element) => {
    $(element).on('mouseover', function () {
      const offset = $(this).offset();
      $('#toolTipContent').css({
        top: offset.top - $(document).scrollTop() + 25,
        left: offset.left - 133,
      });
      $('#toolTipContent').show();
      $(`#${element.split('_')[1]}`).show();
    });
    $(element).on('mouseout', () => {
      $('#toolTipContent').hide();
      $(`#${element.split('_')[1]}`).hide();
    });
    $(document).on('scroll', () => $('#toolTipContent').hide());
  });
};

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  platform: 'bigcommerce',
  SearchRequestDefaults: {
    pageSize: 36,
    selection: landingPage ? [{ field: 'Vehicle', term: landingPage }] : undefined,
    extra: {
      currency: getActiveCurrency(),
    },
  },
  autocomplete: {
    requestDefaults: { extra: { currency: getActiveCurrency() } },
  },
  page: {
    typeToSelectorMap: {
      brand: '.page.brand-page',
    },
    getPageType: (defaults) => (landingPage ? 'parts' : defaults.getter()),
  },
  product: {
    noImageSrc:
      'https://cdn11.bigcommerce.com/s-9u0pekimaz/stencil/73579db0-cd66-013a-4006-0a1c79e31432/e/ad02e100-cf06-013a-2a4e-06cbd7d79ef4/img/ProductDefault.gif',
    formatPrice,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
      { pathname: manufacturersPageUrl, field: 'manufacturers' },
      { pathname: landingPage ? window.location.pathname : null, field: 'category' },
    ],
    categorySelectionPageRedirect: true,
    expectResponseRedirect: true,
    getFitmentSearchTitle,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: '.page.brand-page',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { selector: '.page.category form[action="/compare"] .productList', class: 'cm_main-content' },
      template: 'MainContent',
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: {
        replace: '.page.category #faceted-search-container > nav#convermax-sidebar',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
      updateCallback: SearchResultCallback,
    },
    {
      name: 'Garage',
      location: {
        insertAfter: '.header .user-icons .navUser-item--cart',
        class: 'cm_garage__container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchBox',
      location: { replace: '#quickSearch .container .form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.user-icons .quicksearch',
        class: 'navUser-item quicksearch mobile',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: { selector: '#cm_ymm', class: 'cm_vehicle-widget__header-container' },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      // hide all vehicle widgets to not send a selected vehicle in the request
      visibleIf: () => window.location.pathname !== manufacturersPageUrl,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'HeaderVehicleWidget__categoryListLabel',
      type: 'VehicleWidget',
      location: '.page-heading',
      template: 'fitmentSearch/categoryListVehicleLabel',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetManufacturersTiles',
      type: 'FacetTiles',
      location: { selector: '#cm_manufacturers', class: 'cm_vehicle-categories__manufacturers' },
      template: 'facetTiles/manufacturersContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'RelatedItems',
      location: '#tab-related',
      template: 'product/relatedItems',
      count: 5,
      getSelection,
    },
    {
      name: 'VehicleLanding',
      type: 'FacetTiles',
      location: '[data-content-region="category_below_content"]',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => !!landingPage,
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm_verify_fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '.product-fitment',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: 'h3.product-fitment + div[role="tabpanel"]',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
